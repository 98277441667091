.footer {
    background: $bg_dark;
    height: 92vh;
    max-height: 92vh;
    color: #D7D3CA;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 600px) {
      height: 100vh;
      max-height: 100vh;
    }
    @media (min-width: 600px) {
      height: 92vh;
      max-height: 92vh;
    }
  }

  .footerRow{
    margin-top: -8vh;
    margin-bottom: 16vh;
  }
  
  .footer-logo-container {
    align-self: center;
    text-align: center;
    @media (max-width: 600px) {
      padding-top: 20px;
    }
  }
  
  .footer-logo {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  .footer a:hover {
    color: white;
    text-decoration: none;
  }
  
  .footer a {
    color: #D7D3CA;
  }
  