#Navbar {
  .navbar-bg {
    background: $bg_dark;
    min-height: 8vh;
  }

  ul {
    margin: 0;
    padding: 0 ;
  }

  .nav-link {
    @media (max-width: 600px) {
      text-align: center !important;
      margin: 1.5vh 0;
    }
  }
}
