@import "variables";
// @import "reset";
@import "header";
@import "navbar";
@import "timeline";
@import "footer";
@import "media";

html {
  scroll-behavior: smooth;
}

.App {
  color: black;
  background-image: url("../Images/bg.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.container {
  max-width: 920px !important;
}

.section {
  padding:2vh 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  @media (min-width: 600px) {
    min-height: 92vh;
  }
}

.section-titles {
  font-size: 2.5em;
  text-align: center;
  padding: 5vh 5vw;
  text-transform: uppercase;
  font-weight: 600;
  margin: 2vh 0;
}

ul {
  padding: 0 20px;
}

body {
  font-family: 'Quicksand', sans-serif !important;
}

#streaming {
    .brand-logo {
      font-size: 2em;
      align-items: "center";
    }
    a{
      color:#3C3C3C;
    }
    a:hover {
      color: black; 
    }
}