#media {
  .projectCard {
    padding: 0;
    background-color:rgba(0, 0, 0, 0.4);
    border:none;
    color: #D7D3CA;
  }

  .linkSection {
    display: flex;
    justify-content: center;
    margin-top: 1vh;
    :hover {
      text-decoration: none;
    }
  }
  a {
    margin: 0 auto;
    text-align: center;
    text-decoration: none;
    :hover {
      text-decoration: none;
      background-color: rgba(0, 0, 0, 0.8);
    }
  }
  h6 {
    margin-top: 2vh;
    font-weight: 600;
    color: #D7D3CA;
    text-decoration: none;
  }
}
