#header {
  .header {
    background: $bg_dark;
    height: 92vh;
    min-height: 92vh;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .header-contents {
      display: flex;
      flex-direction: row;
      justify-content: center;
      color: #666666;
      text-align: left;
      @media (max-width: 600px) {
        text-align: center;
        font-size: 13px;
      }
    }

    .header-text {
      max-width: 350px;
      margin: 2vh 2vw;
    }

    .header-title {
      font-size: 2.2em;
      text-align: left;
      text-transform: uppercase;
      @media (max-width: 600px) {
        text-align: center;
      }
    }

    .header-img {
      display: flex;
      
      img {
        height: 90vh;
        margin: 0 auto;
        @media (max-width: 600px) {
          height: auto;
          width:100%;
          margin: auto;
        }
        // @media (min-width: 600px) {
        //   margin-left: 5vw;
        // }
      }
    }
  }
}
